.ant-form-item-control-wrapper{
    width: 100%;
}
.ant-input-number{
    width: 100% !important;
}
.cu-tabs-profile-page .ant-tabs-nav-container{
    width: 100%;
    text-align: left;
    display: flex;
}
.ant-menu-inline-collapsed{
    width: 60px !important;
}
.ant-table-thead > tr > th{
    background: #ebebeb !important;
    color: #000;
    font-weight: 500;
}

.thumbnail-file-icons{
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.upload-input{
    height: 100px;
    width: 100px;
    outline: none;
    border: 1px dashed gray;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 35px;
    color: gray;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-input img{
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: contain;
}

.gx-layout-sider-scrollbar-menu{
    height: calc(100vh - 174px) !important;
}